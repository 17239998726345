import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { FaArrowLeft, FaChevronDown, FaChevronUp } from "react-icons/fa";
import { orderService } from '../api/orderService';
import {  } from 'date-fns';
import API_URL from '../api/config';


const OrderDetails = () => {
    const { commandeId } = useParams();
    const [order, setOrder] = useState(null);
    const [error, setError] = useState(null);
    const [openAccordion, setOpenAccordion] = useState(null);
    const [modifiedOrder, setModifiedOrder] = useState({});
    const [isFormVisible, setIsFormVisible] = useState(false);
    const downloadFile = async (fileType) => {
        try {
            const response = await fetch(`${API_URL}/downloads/${commandeId}?type=${fileType}`, {
                method: 'GET',
            });
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `order-${commandeId}.${fileType}`;
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error(`Erreur lors du téléchargement du ${fileType.toUpperCase()}:`, error);
        }
    };
    useEffect(() => {
        const fetchOrderDetails = async () => {
            try {
                const data = await orderService.fetchOrderById(commandeId);
                setOrder(data);
                setModifiedOrder({ customerEmail: data.customerEmail }); // Initialise l'email du client
            } catch (error) {
                setError('Erreur lors de la récupération des détails de la commande: ' + error.message);
            }
        };

        fetchOrderDetails();
    }, [commandeId]);        

    const toggleFormVisibility = () => {
        console.log('Toggling form visibility from', isFormVisible, 'to', !isFormVisible); 
        setIsFormVisible(!isFormVisible);
    };

    const handleModificationChange = (event) => {
        const { name, value, dataset } = event.target;
        setModifiedOrder(prev => ({
            ...prev,
            [dataset.section ? `${dataset.section}.${name}` : name]: value,
        }));
    };

    const handleDuplicateOrder = async (e) => {
        e.preventDefault(); 
    
        if (!order) {
            console.error('Les détails de la commande ne sont pas chargés.');
            alert("Les détails de la commande ne sont pas encore chargés.");
            return;
        }
        const formattedCreatedAt = order.createdAt;
        const formattedUpdatedAt = new Date().toISOString();
        const newOrderData = {
            ...order, 
            ...modifiedOrder, 
            id: undefined, 
            incrementId: `Copy - ${order.incrementId}`,
            createdAt: formattedCreatedAt,
            updatedAt: formattedUpdatedAt,            
            orderItems: order.orderItems.map(item => ({
                ...item,
                qtyOrdered: modifiedOrder[`productQuantity-${item.id}`] || item.qtyOrdered,
            })),
        };

        try {
            const newOrder = await orderService.createDuplicateOrder(newOrderData);
            console.log('Nouvelle commande créée:', newOrder);
            alert('Nouvelle commande dupliquée et créée avec succès!');
        } catch (error) {
            console.error('Erreur lors de la duplication de la commande:', error);
            setError('Erreur lors de la création de la commande. Veuillez réessayer.');
        }
    };
        



    const renderOrderModificationForm = () => {
        if (!order) return <div>Chargement des informations de commande...</div>;
    
        return (
            <form onSubmit={handleDuplicateOrder} className="space-y-4">
                {/* Email du Client */}
                <div>
                    <label htmlFor="customerEmail" className="block text-sm font-medium text-gray-700">Email du Client</label>
                    <input
                        type="email"
                        name="customerEmail"
                        id="customerEmail"
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        value={modifiedOrder.customerEmail || ''} 
                        onChange={handleModificationChange}
                    />
                </div>
    
                <h3 className="text-lg font-semibold text-gray-700">Adresse de Livraison</h3>
                {/* Rue de livraison */}
                <div>
                    <label htmlFor="billingAddressStreet" className="block text-sm font-medium text-gray-700">Rue de facturation</label>
                    <input
                        type="text"
                        name="billingAddressStreet"
                        id="billingAddressStreet"
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        value={modifiedOrder.billingAddress?.street || ''}
                        onChange={(e) => setModifiedOrder({
                            ...modifiedOrder,
                            billingAddress: { ...modifiedOrder.billingAddress, street: e.target.value }
                        })}
                    />
                </div>
                <div>
                    <label htmlFor="billingAddressPostCode" className="block text-sm font-medium text-gray-700">Code Postal</label>
                    <input
                        type="text"
                        name="billingAddressPostCode"
                        id="billingAddressPostCode"
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        value={modifiedOrder.billingAddress?.postcode || ''}
                        onChange={(e) => setModifiedOrder({
                            ...modifiedOrder,
                            billingAddress: { ...modifiedOrder.billingAddress, postcode: e.target.value }
                        })}
                    />
                </div>
                {/* Ville */}
                <div>
                    <label htmlFor="billingAddressCity" className="block text-sm font-medium text-gray-700">Ville</label>
                    <input
                        type="text"
                        name="billingAddressCity"
                        id="billingAddressCity"
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        value={modifiedOrder.billingAddress?.city || ''}
                        onChange={(e) => setModifiedOrder({
                            ...modifiedOrder,
                            billingAddress: { ...modifiedOrder.billingAddress, city: e.target.value }
                        })}
                    />
                </div>
                {/* Pays */}
                <div>
                    <label htmlFor="billingAddressCountryId" className="block text-sm font-medium text-gray-700">Pays</label>
                    <input
                        type="text"
                        name="billingAddressCountryId"
                        id="billingAddressCountryId"
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        value={modifiedOrder.billingAddress?.countryId || ''}
                        onChange={(e) => setModifiedOrder({
                            ...modifiedOrder,
                            billingAddress: { ...modifiedOrder.billingAddress, countryId: e.target.value }
                        })}
                    />
                </div>
                {/* Région */}    
                {/* Articles Commandés et Quantité */}
                {order.orderItems && order.orderItems.map((item, index) => (
                    <div key={index} className="space-y-2">
                        <h4 className="text-md font-semibold text-gray-700">Produit: {item.name}</h4>
                        <div>
                            <label htmlFor={`productQuantity-${item.id}`} className="block text-sm font-medium text-gray-700">Quantité</label>
                            <input
                                type="number"
                                name={`productQuantity-${item.id}`}
                                id={`productQuantity-${item.id}`}
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                defaultValue={item.qtyOrdered}
                                onChange={handleModificationChange}
                            />
                        </div>
                    </div>
                ))}
    
    
                <button
                    type="submit"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                    Dupliquer la commande
                </button>
            </form>
        );
    };
    


    const toggleAccordion = (index) => {
        setOpenAccordion(openAccordion === index ? null : index);
    };
    
    const renderProductCustomization = (productOptions) => {
        const { customOptions, bundleOptions } = productOptions;
    
        const getOptionSelectionDetails = (selection) => {
            const name = selection.name || selection.title || selection.label;
            const id = selection.id || selection.optionId || 'ID inconnu';
            return name ? `${name} (ID: ${id})` : null;
        };
    
        return (
            <div className="mt-4">
                {customOptions && customOptions.length > 0 && (
                    <div className="mb-4">
                        <h4 className="text-md font-semibold text-blue-600 mb-2">Options personnalisées:</h4>
                        <ul className="list-disc list-inside space-y-1">
                            {customOptions.map((option, index) => (
                                <li key={index} className="text-sm text-gray-600">
                                    <span className="font-medium text-gray-800">{option.optionLabel}:</span> {option.optionValue} {option.value}<span className="text-gray-500">(ID: {option.optionId})</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                {bundleOptions && bundleOptions.length > 0 && (
                    <div>
                        <h4 className="text-md font-semibold text-blue-600 mb-2">Options de pack:</h4>
                        <ul className="list-disc list-inside space-y-1">
                            {bundleOptions.map((bundle, index) => (
                                <li key={index} className="text-sm text-gray-600">
                                    <span className="font-medium text-gray-800">{bundle.optionQty} x {bundle.optionTitle}</span>
                                    <ul className="list-disc list-inside ml-5 space-y-1">
                                        {bundle.optionSelections.map((selection, idx) => {
                                            const details = getOptionSelectionDetails(selection);
                                            return details ? (
                                                <li key={idx} className="text-sm text-gray-600">
                                                    {details}
                                                </li>
                                            ) : null;
                                        })}
                                    </ul>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        );
    };

    const renderSubItems = (parentItemId) => {
        return order.orderItems
            .filter(item => item.parentItem && item.parentItem['@id'] === parentItemId)
            .map((subItem, index) => (
                <div key={index} className="p-2 mt-2 bg-gray-100 rounded border">
                    <p className="font-semibold">Sous-Produit: {subItem.name}</p>
                    <p>SKU: {subItem.sku}</p>
                    <p>Type de produit: {subItem.productType}</p>
                    <p>Taxe: {subItem.taxAmount}</p>
                    <p>Prix: {subItem.basePrice} €</p>
                    <p>Quantité: {subItem.qtyOrdered}</p>
                </div>
            ));
    };


    const renderShippingDetails = () => {
        const isDifferent = order.billingAddress && order.shippingAddress && 
                            (order.billingAddress.street !== order.shippingAddress.street || 
                            order.billingAddress.city !== order.shippingAddress.city ||
                            order.billingAddress.postcode !== order.shippingAddress.postcode);
    
        return (
            <div>
                <h3 className="text-xl font-semibold text-gray-700">Détails de Livraison</h3>
                {isDifferent ? (
                    <div className="space-y-2">
                        <p className="text-gray-600">{order.shippingAddress.firstname} {order.shippingAddress.lastname}</p>
                        <p className="text-gray-600">{order.shippingAddress.street}</p>
                        <p className="text-gray-600">{order.shippingAddress.postcode}, {order.shippingAddress.city}</p>
                        <p className="text-gray-600">{order.shippingAddress.countryId}</p>
                        <p className="text-gray-600">Téléphone: {order.shippingAddress.telephone}</p>
                    </div>
                ) : (
                    <p className="text-gray-600">L'adresse de livraison est identique à l'adresse de facturation.</p>
                )}
                <p className="text-gray-600">Méthode d'expédition: {order.shippingDescription}</p>
                <p className="text-gray-600">Montant d'expédition: {order.shippingAmount} €</p>
            </div>
            
        );
    };

    if (error) {
        return <div className="text-red-500 text-center">Erreur: {error}</div>;
    }

    if (!order) {
        return <div className="text-center">Chargement des détails de la commande...</div>;
    }
    console.log('Form visibility before return:', isFormVisible);
    return (
        <div className="container mx-auto px-4 py-8 max-w-7xl">
            <h2 className="text-3xl font-semibold text-gray-900 mb-6">Détails de la Commande #{order?.incrementId}</h2>
            <div className="flex justify-between items-center mb-8">
                <Link to="/commande" className="flex items-center text-blue-600 hover:text-blue-800 transition duration-300 ease-in-out">
                    <FaArrowLeft className="mr-2" /> Retour à la liste des commandes
                </Link>
                <button onClick={toggleFormVisibility} className={`px-4 py-2 border rounded-md shadow-sm text-sm font-medium transition duration-300 ease-in-out ${isFormVisible ? 'bg-blue-600 text-white hover:bg-blue-700' : 'bg-gray-100 text-gray-700 hover:bg-gray-200'}`}>
                    {isFormVisible ? 'Cacher le formulaire' : 'Modifier la commande'}
                </button>
            </div>
            
            {/* Boutons de téléchargement */}
            <div className="flex justify-between items-center mb-8">
                <button onClick={() => downloadFile('pdf')} className="px-4 py-2 border rounded-md shadow-sm text-sm font-medium bg-blue-600 text-white hover:bg-blue-700 transition duration-300 ease-in-out">
                    Télécharger le PDF
                </button>
                <button onClick={() => downloadFile('xml')} className="px-4 py-2 border rounded-md shadow-sm text-sm font-medium bg-green-600 text-white hover:bg-green-700 transition duration-300 ease-in-out">
                    Télécharger le XML
                </button>
            </div>

            {isFormVisible && renderOrderModificationForm()}

            <div className="bg-white shadow-xl rounded-xl p-6 space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-8">
                    <div className="space-y-4">
                        <h3 className="text-xl font-semibold text-gray-700">Informations Générales</h3>
                        <p>Date de Création: <span className="font-semibold text-gray-600">{order.createdAt}</span></p>
                        <p>Email du Client: <span className="font-semibold text-gray-600">{order.customerEmail}</span></p>
                        <p>Poids: <span className="font-semibold text-gray-600">{order.weight}</span></p>
                        <p>Groupe de clients: <span className="font-semibold text-gray-600">{order.customerGroupId}</span></p>
                        <p>Pays: <span className="font-semibold text-gray-600">{order.billingAddress.countryId}</span></p>
                        <p>Canal: <span className="font-semibold text-gray-600">{order.channel.code}</span></p>
                        {/*<p>Total: <span className="font-semibold text-gray-600">{order.baseGrandTotal} €</span></p>*/}
                    </div>
                    <div className="space-y-4">
                        <h3 className="text-xl font-semibold text-gray-700">Adresse de Facturation</h3>
                        <p className="text-gray-600">
                            {order.billingAddress.company}<br/>
                            {order.billingAddress.firstname} {order.billingAddress.lastname} <br/>
                            {order.billingAddress.street}<br/>
                            {order.billingAddress.postcode},<br/>  {order.billingAddress.regionCode} {order.billingAddress.city}<br/> 
                            {order.billingAddress.countryId} <br/> 
                            {order.billingAddress.telephone}
                        </p>
                    </div>
                    <div className="space-y-4">
                <h3 className="text-xl font-semibold text-gray-700">Détails Financiers</h3>
                <p>Devise de la Commande: <span className="font-semibold text-gray-600">{order.baseCurrencyCode}</span></p>
                <p>Sous-total: <span className="font-semibold text-gray-600">{order.baseSubtotal} {order.baseCurrencyCode}</span></p>
                <p>Taxe Totale: <span className="font-semibold text-gray-600">{order.baseTaxAmount} {order.baseCurrencyCode}</span></p>
                <p>Frais de Livraison: <span className="font-semibold text-gray-600">{order.shippingAmount} {order.orderCurrencyCode}</span> (Inclus dans le total)</p>
                <p className="font-semibold">Total Commande: <span className="font-bold text-gray-800 ">{order.baseGrandTotal} {order.baseCurrencyCode}</span></p>
            </div>
                    <div className="mt-6 space-y-4 lg:mt-0">
                        <h3 className="text-xl font-semibold text-gray-700">Informations de Paiement</h3>
                        <p className="text-gray-600">Méthode: {order.payment.method}</p>
                        <p className="text-gray-600">Coupon de reduction: {order.couponCode}</p>
                        <p className="text-gray-600">Sous-total: {order.baseSubtotal} €</p>
                        <p className="text-gray-600">Taxe: {order.baseTaxAmount} €</p>
                        <p className="text-gray-600">Frais de Livraison: {order.shippingAmount} €</p>
                        <p className="font-semibold">Montant: {order.payment.amountOrdered} €</p>

                        {renderShippingDetails()}
                    </div>
                </div>
                {order.promoCode && (
    <p className="text-gray-600">Code Promo Utilisé: {order.promoCode}</p>
)}
                <div className="mt-8">
                    <h3 className="text-xl font-bold text-gray-700 mb-4">Articles Commandés</h3>
                    {order.orderItems.filter(item => !item.parentItem).map((item, index) => (
                        <div key={index} className="mb-4">
                            <div className="flex justify-between items-center p-2 border-b cursor-pointer" onClick={() => toggleAccordion(index)}>
                                <span className="font-semibold text-gray-700">{item.name}</span>
                                {openAccordion === index ? <FaChevronUp /> : <FaChevronDown />}
                            </div>
                            {openAccordion === index && (
                                <div className="p-2 space-y-2">
                                    <p className="font-semibold text-gray-700">Nom: {item.name}</p>
                                    <p className="text-gray-600">SKU: {item.sku}</p>
                                    <p className="text-gray-600">Id Produit: {item.productId}</p>
                                    <p className="text-gray-600">Prix: {item.basePriceInclTax} €</p>
                                    <p className="text-gray-600">Taxe: {item.taxAmount} €</p>
                                    <p className="text-gray-600">Montant Taxe: {item.taxAmount} €</p>          
                                    <p className="text-gray-600">Quantité: {item.qtyOrdered}</p>
                                    {item.productOption && renderProductCustomization(item.productOption)}
                                    {renderSubItems(item['@id'])}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default OrderDetails;
