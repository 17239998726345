import API_URL from './config';

export const downloadExport = async (type, columns = [], separator = ';', channel = '') => {
    const params = new URLSearchParams();
    if (columns.length) params.append('columns', columns.join(','));
    params.append('separator', separator);
    if (channel) params.append('channel', channel);

    const response = await fetch(`${API_URL}/${type}?${params.toString()}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    return response.blob();
};
