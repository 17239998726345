import API_URL from './config';

export async function authenticate(username, password) {
    console.log("patate");
    try {
        const response = await fetch(`${API_URL}/login_check`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username, password }),
        });

        if (!response.ok) {
            const errorText = await response.text(); // Ajout pour voir le corps de la réponse en cas d'erreur
            console.error('Erreur réponse API:', errorText);
            throw new Error('Échec de la connexion');
        }

        const data = await response.json();
        localStorage.setItem('user', JSON.stringify({ token: data.token, role: data.role })); 
        return data;
    } catch (error) {
        console.error('Erreur lors de la tentative de connexion:', error.message);
        throw error;
    }
}

export function logoutUser() {
    localStorage.removeItem('token');
}